export const ADD_TODO = "ADD_TODO";
export const TOGGLE_TODO = "TOGGLE_TODO";
export const SET_FILTER = "SET_FILTER";

export const ADD_USER = "ADD_USER";
export const REMOVE_USER = "REMOVE_USER";

export const ADD_COUNT = "ADD_COUNT"
export const BUY_CAKE = "BUY_CAKE"
export const SET_AUTH_USER = "SET_AUTH_USER"
export const REMOVE_AUTH_USER = "REMOVE_AUTH_USER"
